// Landing.jsx
import "./Landing.css";

import { Badge, Container, Nav, Navbar } from "react-bootstrap";
import { Title } from "../components/title/Title";
import { Footer } from "../components/landing/footer/Footer";

import { Axis } from "../components/landing/axis/Axis";
import { WordsCarrousel } from "../components/landing/footer/WordsCarrousel/WordsCarrousel";

import Logo from "../assets/logos/XYZ-white.png";
import { ReactComponent as Insta } from "../assets/icons/instagram.svg";
import { Splash } from "./splash/Splash";

export const Landing = () => {
  return (
    <>
      <Splash />

      {/* Navbar */}

      <Navbar
        className="mynavbar justify-content-between pt-3"
        style={{ zIndex: 200 }}
      >
        <Container>
          <Navbar.Brand href="#home">
            <p style={{ color: "white" }}>
              {" "}
              <strong>conpasion</strong>.xyz
            </p>
          </Navbar.Brand>

          {/* Instagram Logo  */}
          <div className="d-flex">
            <a
              href="https://www.instagram.com/p/Cx1NhnguJw0/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <Insta fill="white" />
            </a>
          </div>
        </Container>
      </Navbar>

      {/* Sections  */}

      <section className="page-section">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <Title />

          {/* <WordsCarrousel/> */}
        </div>
      </section>
      <Axis />
      <Footer />
    </>
  );
};
