import { Card } from "../../common/cards/Card";
import educacion from "../../../assets/icons/educacion.png";
import bienestar from "../../../assets/icons/bienestar.png";
import diversidad from "../../../assets/icons/diversidad.png";
import infraestructura from "../../../assets/icons/infraestructura.png";
import proyeccion from "../../../assets/icons/proyeccion.png";
import sinergia from "../../../assets/icons/sinergia.png";

import "./styles.css";

export const Axis = () => {
  const data = [
    {
      title: "Modelo Educativo",
      subtitle: "Resignificación de la experiencia como estudiantes.",
      content: [
        "Proponer la implementación de modelos de aprendizaje prácticos con pedagogía basada en proyectos",
        "Crear de un consultorio de Ingeniería gratuito que permita el desarrollo practico de habilidades",
        "Generar una robusta red de contactos y trabajo colaborativo entre grupos estudiantiles",
        "Fomentar la Innovación tecnológica por medio de la aplicación de nuevas tecnologías en las aulas",
      ],
      link: "",
      color: "modelo-educativo",
      src: educacion,
    },
    {
      title: "Infraestructura y Financiamiento",
      subtitle:
        "Nuestra misión es informar y empoderar a la comunidad de la facultad sobre oportunidades de financiamiento y propender por el mejoramiento de las instalaciones físicas.",
      content: [
        "Diagnosticar y crear espacios de reflexión alrededor del proceso de ejecución de la política de gratuidad con el fin de afrontar la implementación de la ley 2307 del 2023",
        "Generar un diagnóstico del estado de equipos de laboratorios de la Facultad",
        "Resignificar y exaltar la extensión de la Facultad, comprendiéndolo como una oportunidad para el estudiantado y un mecanismo de intercambio de conocimiento",
      ],
      link: "",
      color: "infraestructura-financiamiento",
      src: infraestructura,
    },
    {
      title: "Investigación y Proyección Laboral",
      subtitle:
        "Fomentar el desarrollo estudiantil mediante una plataforma que conecta con oportunidades de investigación y crecimiento profesional",
      content: [
        "Crear de una plataforma de contacto entre actores de sectores empresariales públicos/privados y estudiantes que brinde la oportunidad de acercamiento a oportunidades de diferente índole.",
        "Fortalecer las relaciones Inter facultades que permitan la diversificación y la interdisciplinariedad en las propuestas de investigación.",
        "Proponer la implementación de un portafolio de proyectos personal como carta de presentación para entornos laborales, comerciales y/o investigativos que refuercen el reconocimiento de nuestra trayectoria estudiantil.",
      ],
      link: "",
      color: "investigacion-proyeccion",
      src: proyeccion,
    },
    {
      title: "Bienestar",
      subtitle:
        "Construir una comunidad universitaria vibrante, centrada en el bienestar y el crecimiento personal, mediante iniciativas integrales y colaborativas. ",
      content: [
        "Supervisar la atención psicológica estudiantil, concienciar sobre salud mental e integrar recursos para fortalecer y ampliar la atención y las estrategias de prevención.",

        "Exigir la contratación de personal para guía y apoyo de equipos y clubes deportivos en la Facultad.",

        "Desarrollar una plataforma que permita un acceso abierto a la programación de actividades extracurriculares desde bienestar, grupos y la Representación estudiantiles.",

        "Contar con un sistema que permita conocer la trayectoria del estudiantado de la Facultad en torneos y diferentes competencias.",
      ],
      link: "",
      color: "bienestar",
      src: bienestar,
    },
    {
      title: "Género y Diversidad",
      subtitle:
        "Abogamos por una comunidad universitaria inclusiva, promoviendo diálogos, fortaleciendo iniciativas y formación libre de violencia y con representación diversa y empática.",
      content: [
        "Construir una representación basada en la empatía con perspectiva de género y poblaciones diversas, apoyados en la línea de genero de la Facultad.",
        "Rechazamos la estigmatización de poblaciones diversas en la comunidad, buscando escenarios educativos y de reflexión para velar por el respeto y la no vulneración de derechos.",
        "Buscar disminuir la brecha de género, trabajando de la mano con colegios e instituciones en el desarrollo de vocaciones tempranas con perspectiva de género, multidisciplinares y humanas.",
      ],
      link: "",
      color: "genero-diversidad",
      src: diversidad,
    },
    {
      title: "Sinergia Universitaria",
      subtitle:
        "Fomentamos la participación activa, articulada y crítica en la comunidad universitaria.",
      content: [
        "Crear una plataforma de recepción de ideas, comentarios y posiciones de la comunidad.",
        "Diagnosticar las estrategias existentes que motivan la colaboración entre facultades y la discusión desde las diferentes áreas del conocimiento.",
        "Crear un espacio divulgativo tipo podcast, el cual impulse la constante conversación sobre temas de interés general en la comunidad.",
      ],
      link: "",
      color: "sinergia-universitaria",
      src: sinergia,
    },
  ];

  const cards = data.map((axis, index) => (
    <Card
      key={index}
      title={axis.title}
      subtitle={axis.subtitle}
      content={axis.content}
      link={axis.link}
      color={axis.color}
      src={axis.src}
    />
  ));

  return (
    <section className="axis-container">
      <h2 className="main-title">
        Ejes de trabajo por la Representación Estudiantil de la Facultad de
        Ingeniería
      </h2>
      <div className="cards-container">{cards}</div>
      <a
        href="https://drive.google.com/file/d/1RfMH9zdaOS6QtxrUXV5iHiB1CyLPLPPP/view?usp=sharing"
        target="_blank"
        className="download-button"
      >
        Descargar Programa Completo
      </a>
    </section>
  );
};
