import { useEffect, useState } from "react";
import "./styles.css";
import Logo from "../../assets/logos/XYZ-white.png"

export const Splash = () =>{
    const [barWidth, setBarWidth] = useState(0); 
    const [show, setShow] = useState(true); 

    useEffect(()=> {
        setBarWidth(100)
        setTimeout(()=> setShow(false), 2000); 
    }, [])
    return (
        <>
        {show && <div className="splash d-flex justify-content-center align-items-center">
            <span className="bar" style={{width: `${barWidth}%`}}></span>
            <img className="logo-splash" src= {Logo} alt="" srcset="" />
        </div>}
        
        </>
    )
}