// title.jsx
import "./styles.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Logo from "./../../assets/logos/XYZ-white.png";
import { Badge, Container, Navbar } from "react-bootstrap";
import Candidates from "../../assets/imgs/candidates.png";
import Number2 from "../../assets/imgs/#2.png";

import Andres from "../../assets/imgs/andres.png";
import Felipe from "../../assets/imgs/felipe.png";
import { useEffect, useState } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

// import { MyNavBar } from "../mynavbar/myNavBar.jsx";

export const Title = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    console.log(`Position: ${position}`);
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      alert(`Hola ${e.target.value}!. ¿Quieres votar por la plancha #2? :v `);
    }
  };

  return (
    <ParallaxProvider>
      <div className="d-flex flex-column justify-content-center align-items-center h-75 building-bg position-relative w-100">
        {/* images */}
        <a
          href="https://instagram.com/mora200217"
          target="_blank"
          className="d-none d-lg-block"
        >
          <img
            src={Andres}
            data-tooltip-id="andres"
            data-tooltip-content="Andrés Morales"
            data-tooltip-place="left"
            className="candidates-img candidates-left"
            style={{ transform: `translatex(${-scrollPosition}px)` }}
            alt=""
            srcset=""
          />
          <Tooltip id="andres" />
        </a>

        <a
          href="https://www.instagram.com/juanfel254/"
          target="_blank"
          className="d-none d-lg-block"
        >
          <img
            src={Felipe}
            data-tooltip-id="felipe"
            data-tooltip-content="J. Felipe Parra"
            data-tooltip-place="left"
            className="candidates-img candidates-right"
            style={{ transform: `translatex(${scrollPosition}px)` }}
            alt=""
            srcset=""
          />
          <Tooltip id="felipe" />
        </a>

        <p
          className="mb-2"
          style={{
            opacity: 1 - scrollPosition / 200,
            color: "white",
            fontSize: "2.4rem",
            fontWeight: 600,
          }}
        >
          <h2
            className="big-title"
            style={{
              color: "white",
              fontWeight: 400,
            }}
          >
            {" "}
            Por la Representación Estudiantil en la Facultad de Ingeniería,
            innovemos desde las pasiones con el <strong>#2</strong>{" "}
          </h2>{" "}
        </p>
        <br />
        <br />
        <br />
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ width: "55vw" }}
        >
          <Parallax speed={-10}>
            <img src={Logo} alt="" srcset="" className="title-logo" />
            <p style={{ color: "white", fontSize: "1vw" }}>
              <em>desde todos los ejes</em>
            </p>
          </Parallax>
        </div>
        <input
          style={{ opacity: 1 - scrollPosition / 200 }}
          type="text"
          placeholder="Pon tu nombre y envía"
          className="search-bar p-2 px-5 mt-2"
          onKeyDown={_handleKeyDown}
        />
      </div>
    </ParallaxProvider>
  );
};
