import logo from './logo.svg';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Landing } from './pages/Landing';
import { Splash } from './pages/splash/Splash';

function App() {
  return (
    <div className="App">
        <Landing /> 
    </div>
  );
}

export default App;
