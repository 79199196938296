import Tilt from "react-parallax-tilt";
import "./styles.css";

export const Card = ({ title, subtitle, src, content, link, color }) => {
  return (
    <Tilt glarePosition="all" scale={1.01} className={`card-container`}>
      <img src={src} alt="logo" className="axis-icon" />
      <div className={`link ${color}`} href={link ? link : "#"}>
        <img src={src} alt="logo" className="axis-icon-mobile" />
        <div className="title-container">
          <h3 className="title">{title}</h3>
          <p className="subtitle">{subtitle}</p>
        </div>
        <div className="description-container">
          <ul className="items-container">
            {content.map((line, index) => {
              return (
                <li className="bullet" key={index}>
                  {line}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Tilt>
  );
};
